import React from "react";
import "./Tafta.css";
import { Col, Container, Row } from "react-bootstrap";

//img imports
import taftaArc1 from "../../img/tafta/tafta-arc1.jpg";
import taftaArc2 from "../../img/tafta/tafta-arc2.jpg";
import taftaOutside from "../../img/tafta/tafta-nadvor.jpg";
import taftaOutsideFinal from "../../img/tafta/tafta-nadvor-final.jpg";
import taftaInterior1 from "../../img/interior/kicInt.jpg";
import taftaInterior2 from "../../img/interior/livInt.jpg";
import taftaGradba1 from "../../img/tafta/taftaGradba1.jpg";
import taftaGradba2 from "../../img/tafta/taftaGradba2.jpg";
// import taftaGradba3 from "../../img/tafta/taftaGradba3.jpg"
import taftaGradba4 from "../../img/tafta/taftaGradba4.jpg";
import taftaGradba5 from "../../img/tafta/taftaGradba5.jpg";

function Tafta(props) {
  return (
    <div className="firstProject pt-5">
      <div className="taftaTitle pb-4">
        <h2>Тафталиџе 2 - ул.Осло бр.4</h2>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="taftaArcMain pb-3">
              <img src={taftaArc1} className="img-fluid taftaArc1 mr-5" alt="" />
              <img src={taftaArc2} className="img-fluid taftaArc2" alt="" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="taftaGradba">
              <img src={taftaGradba1} className="img-fluid" alt="" />
              <img src={taftaGradba2} className="img-fluid" alt="" />
            </div>
            <div className="taftaGradba2">
              <img src={taftaGradba4} className="img-fluid" alt="" />
            </div>
            <div className="taftaGradba2">
              <img src={taftaGradba5} className="img-fluid" alt="" />
            </div>
            <div className="taftaOutMain pb-3">
              <img src={taftaOutside} className="img-fluid mr-5" alt="" />
              <img src={taftaOutsideFinal} className="img-fluid" alt="" />
            </div>
            <div className="taftaInterior">
              <img src={taftaInterior1} className="img-fluid mr-5" alt="" />
              <img src={taftaInterior2} className="img-fluid" alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Tafta;
