import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import "./MainProject.css";
import "../../styles/app.scss";

//google maps
import MapDialog from "../MapDialog/MapDialog";

//icons
import LocationOnIcon from "@mui/icons-material/LocationOn";

//img imports
import taftaProdadeno from "../../img/tafta/renderTafta.jpg";
import vlae from "../../img/vlae/vlae1.jpg";
import debarmaalo from "../../img/dbrmaalo/b-render1.jpg";
// import comingSoon from "../../img/comingSoon.jpg";
// import Footer from "../Footer/Footer";

function MainProject(props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [latitute, setLatitude] = useState(0);
  const [longtitude, setLongtitude] = useState(0);
  const [zoom, setZoom] = useState(10);

  const taftalidzeLat = 41.9993361;
  const taftalidzeLong = 21.3885251;

  const vlaeLat = 42.0086487;
  const vlaeLong = 21.3726393;

  const debarMaaloLat = 42.0003692;
  const debarMaaloLong = 21.4168862;

  const handleOpenDialog = (lat, long, zoom) => {
    setOpenDialog(true);
    setLatitude(lat);
    setLongtitude(long);
    setZoom(zoom);
  };

  return (
    <div className="mainProjects pt-4">
      {openDialog && <MapDialog open={openDialog} setOpenDialog={setOpenDialog} lat={latitute} long={longtitude} zoom={zoom} />}
      <Container fluid>
        <Row className="projects-row">
          <Col lg={4}>
            <div className="mainTafta">
              <h3 className="taftaTitle" style={{ textTransform: "uppercase" }}>
                Објект <span style={{ color: "#ff6e01" }}>Тафталиџе</span>
                <button
                  style={{ border: 0, backgroundColor: "transparent" }}
                  onClick={() => {
                    handleOpenDialog(taftalidzeLat, taftalidzeLong, 17);
                  }}
                >
                  <LocationOnIcon />
                </button>
              </h3>
              <Link to="/projects/tafta">
                <img src={taftaProdadeno} className="taftaImg" alt=""></img>
              </Link>
            </div>
          </Col>
          <Col lg={4}>
            <div className="mainVlae">
              <h3 className="vlaeTitle" style={{ textTransform: "uppercase" }}>
                Објект <span style={{ color: "#ff6e01" }}>Влае</span>
                <button
                  style={{ border: 0, backgroundColor: "transparent" }}
                  onClick={() => {
                    handleOpenDialog(vlaeLat, vlaeLong, 17);
                  }}
                >
                  <LocationOnIcon />
                </button>
              </h3>
              <Link to="/projects/vlae">
                <img src={vlae} className="vlaeImg" alt=""></img>
              </Link>
            </div>
          </Col>
          <Col lg={4}>
            <div className="mainDebarMaalo">
              <h3 className="debarMaaloTitle" style={{ textTransform: "uppercase" }}>
                Објект <span style={{ color: "#ff6e01" }}>Дебар Маало</span>
                <button
                  style={{ border: 0, backgroundColor: "transparent" }}
                  onClick={() => {
                    handleOpenDialog(debarMaaloLat, debarMaaloLong, 18);
                  }}
                >
                  <LocationOnIcon />
                </button>
              </h3>
              <Link to="/projects/debarmaalo">
                <img src={debarmaalo} className="vlaeImg" alt=""></img>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </div>
  );
}

export default MainProject;
