import React from "react";
import "./Gallery.css";
import { Col, Container, Row } from "react-bootstrap";

//img imports

//final - vlae
import final1 from "../../img/vlae/final1.jpg";
import final2 from "../../img/vlae/final2.jpg";
import final3 from "../../img/vlae/final3.jpg";
import final4 from "../../img/vlae/final4.jpg";
import final5 from "../../img/vlae/final5.jpg";
import final6 from "../../img/vlae/final6.jpg";
import final7 from "../../img/vlae/final7.jpg";
import final8 from "../../img/vlae/final8.jpg";
import final9 from "../../img/vlae/final9.jpg";
import final10 from "../../img/vlae/final10.jpg";
import final11 from "../../img/vlae/final11.jpg";
import final12 from "../../img/vlae/final12.jpg";
import final13 from "../../img/vlae/final13.jpg";

//img outside - vlae
import prostor from "../../img/vlae/prostor.jpg";
import zgrada1 from "../../img/vlae/zgrada1.jpg";
import zgradaStrana2 from "../../img/vlae/zgradaStrana2.jpg";
import zgrada2 from "../../img/vlae/zgrada2.jpg";
import zgradaStrana from "../../img/vlae/zgradaStrana.jpg";
import nadvorMerac from "../../img/vlae/nadvorMerac.jpg";
import vnatre1 from "../../img/vlae/vnatre1.jpg";
import vnatre2 from "../../img/vlae/vnatre2.jpg";
import vnatreMajstori from "../../img/vlae/vnatreMajstori.jpg";
import vnatre3 from "../../img/vlae/vnatre3.jpg";
import vnatre4 from "../../img/vlae/vnatre4.jpg";
import vnatre5 from "../../img/vlae/vnatre5.jpg";
import vnatre6 from "../../img/vlae/vnatre6.jpg";

import zgrada3 from "../../img/vlae/zgrada3.jpg";
import zgrada4 from "../../img/vlae/zgrada4.jpg";
import zgrada5 from "../../img/vlae/zgrada5.jpg";

//img debar_maalo
import fnl1 from "../../img/dbrmaalo/fnl1.jpg";
import fnl2 from "../../img/dbrmaalo/fnl2.jpg";
import fnl3 from "../../img/dbrmaalo/fnl3.jpg";
import fnl4 from "../../img/dbrmaalo/fnl4.jpg";
import fnl5 from "../../img/dbrmaalo/fnl5.jpg";
import fnl6 from "../../img/dbrmaalo/fnl6.jpg";

//img taftalidze
import taftaInterior1 from "../../img/interior/kicInt.jpg";
import taftaInterior2 from "../../img/interior/livInt.jpg";

function Gallery(props) {
  return (
    <div className="secondProject pt-5">
      <Container>
        <Row>
          <Col className="renderCol">
            <div>
              <img src={final13} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final6} className="img-fluid" alt="" />
            </div>
            <Row>
              <div>
                <img src={fnl1} alt="" style={{ width: "50%" }} className="p-1" />
                <img src={fnl2} alt="" style={{ width: "50%" }} className="p-1" />
              </div>
              <div>
                <img src={fnl3} alt="" style={{ width: "50%" }} className="p-1" />
                <img src={fnl4} alt="" style={{ width: "50%" }} className="p-1" />
              </div>
            </Row>
            <div>
              <img src={final7} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final8} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final9} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final10} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final12} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final1} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final2} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final3} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final4} className="img-fluid" alt="" />
            </div>
            <div className="taftaInterior">
              <img src={taftaInterior1} className="img-fluid mr-5" alt="" />
              <img src={taftaInterior2} className="img-fluid" alt="" />
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={final5} style={{ width: "50%" }} alt="" />
              <img src={final11} style={{ width: "50%" }} alt="" />
            </div>
            <div>
              <img src={prostor} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={zgrada1} className="img-fluid" alt="" />
            </div>
            <div className="lastImages">
              <div>
                <img src={zgradaStrana2} alt="" />
              </div>
              <div>
                <img src={zgrada2} alt="" />
              </div>
              <div>
                <img src={fnl5} alt="" style={{ width: "50%" }} className="p-1" />
                <img src={fnl6} alt="" style={{ width: "50%" }} className="p-1" />
              </div>
              <div>
                <img src={zgradaStrana} alt="" />
              </div>
              <div>
                <img src={nadvorMerac} alt="" />
              </div>
              <div>
                <img src={vnatre1} alt="" />
              </div>
              <div>
                <img src={vnatre2} alt="" />
              </div>
              <div>
                <img src={vnatreMajstori} alt="" />
              </div>
              <div>
                <img src={vnatre3} alt="" />
              </div>
              <div>
                <img src={vnatre4} alt="" />
              </div>
              <div>
                <img src={vnatre5} alt="" />
              </div>
              <div>
                <img src={vnatre6} alt="" />
              </div>
              <div>
                <img src={zgrada3} alt="" />
              </div>
              <div>
                <img src={zgrada4} alt="" />
              </div>
              <div>
                <img src={zgrada5} alt="" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Gallery;
