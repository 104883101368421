import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer(props) {
  return (
    <div className="mainFooter">
      <Container>
        <Row className="firstRow">
          <Col md={4}>
            <Link to="/contact">Contact</Link>
          </Col>
          <Col md={4}>
            <p>&#169; 2021 MCC Construction</p>
          </Col>
          <Col md={4} className="socialsRow">
            <a
              href="https://www.facebook.com/mccconstraksn"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-facebook-square"></i>
            </a>
            <a
              href="https://www.instagram.com/mccconstrution/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="mailto:mccconstraksn2025@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <i className="far fa-envelope"></i>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
