import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function MapDialog({ open, setOpenDialog, lat, long, zoom }) {
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 6 }}>
        <DialogTitle></DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenDialog(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <iframe title="maps" width="100%" height="500" id="gmap_canvas" src={`https://maps.google.com/maps?q=${lat},${long}&z=${zoom}&output=embed`} frameborder="0" marginheight="0" marginwidth="0" />
      </DialogContent>
    </Dialog>
  );
}

export default MapDialog;
