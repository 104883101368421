import React from "react";
import "./Vlae.css";
import { Col, Container, Row, Carousel } from "react-bootstrap";

//img street view
import streetView from "../../img/dbrmaalo/streetView.jpg";

//img imports
import render1 from "../../img/dbrmaalo/b-render1.jpg";
import render2 from "../../img/dbrmaalo/b-render2.jpg";
import render3 from "../../img/dbrmaalo/b-render3.jpg";
import render4 from "../../img/dbrmaalo/b-render4.jpg";
import render5 from "../../img/dbrmaalo/b-render5.jpg";
import render6 from "../../img/dbrmaalo/b-render6.jpg";
import render7 from "../../img/dbrmaalo/b-render7.jpg";
import render8 from "../../img/dbrmaalo/b-render8.jpg";

import render11 from "../../img/dbrmaalo/a-render1.jpg";
import render22 from "../../img/dbrmaalo/a-render2.jpg";

//img debar_maalo
import fnl1 from "../../img/dbrmaalo/fnl1.jpg";
import fnl2 from "../../img/dbrmaalo/fnl2.jpg";
import fnl3 from "../../img/dbrmaalo/fnl3.jpg";
import fnl4 from "../../img/dbrmaalo/fnl4.jpg";
import fnl5 from "../../img/dbrmaalo/fnl5.jpg";
import fnl6 from "../../img/dbrmaalo/fnl6.jpg";

function DebarMaalo(props) {
  return (
    <div className="secondProject pt-5">
      <div className="vlaeTitle pb-4">
        <h2>Дебар Маало - ул.Мајаковски бр. 7</h2>
      </div>
      <Container>
        <Row className="introVlae">
          <Col md={12} className="p-5">
            <p>Мала зграда во Дебар Маало со вкупно девет стана на прекрасна локација во срцето на Скопје (позади Броз кафе). Елаборат за енергетски ефикасен дом и сеизмичка стабилност на објектот одобрен од "Изиис"-Скопје. Германски 6 коморни "Salamander" профили со трослојно 4 сезонско стакло и германски оков "Roto". Лифт од брендот "Otis". Италијански собни врати "Di Porte". Првокласен дабов трослоен паркет во собите. Првоклсани гранитни плочки во бањата,кујната и терасите.</p>
          </Col>
        </Row>
        <Row>
          <img src={streetView} alt="" className="img-fluid pb-4" />
        </Row>
        <Row>
          <div>
            <img src={fnl1} alt="" style={{ width: "50%" }} className="p-1" />
            <img src={fnl2} alt="" style={{ width: "50%" }} className="p-1" />
          </div>
          <div>
            <img src={fnl3} alt="" style={{ width: "50%" }} className="p-1" />
            <img src={fnl4} alt="" style={{ width: "50%" }} className="p-1" />
          </div>
          <div>
            <img src={fnl5} alt="" style={{ width: "50%" }} className="p-1" />
            <img src={fnl6} alt="" style={{ width: "50%" }} className="p-1" />
          </div>
        </Row>
        <Row>
          <Col>
            <div className="carousel pb-4">
              <Carousel>
                <Carousel.Item>
                  <img className="d-block w-100" src={render1} alt="First slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={render2} alt="Second slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={render3} alt="Second slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={render4} alt="Second slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={render5} alt="First slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={render6} alt="First slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={render7} alt="First slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={render8} alt="First slide" />
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="renderCol">
            <div>
              <img src={render11} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={render22} className="img-fluid" alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DebarMaalo;
