import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import "./Homepage.css";
import arhitect from "../../img/arhitekt.jpg";
import kicInt from "../../img/interior/kicInt.jpg";
import livInt from "../../img/interior/livInt.jpg";

function Homepage(props) {
  return (
    <div className="mainHomepage">
      <div className="firstRow">
        <h1>
          MCC <span>Construction</span>
        </h1>
      </div>
      <div className="secondRow">
        <Container>
          <Row className="mt-5 pt-5 pb-5 aboutCompanyRow">
            <Col lg={7} className="pt-5 aboutCompany">
              <h1>
                За <span>Компанијата</span>
              </h1>
              <p>
                MCC Construction е современа градежна компанија која гради нови
                станови во Скопје на одбрани локации и чија основна
                дејност е градежен инжинеринг и изградба на станбени и станбено
                деловни објекти. Компанијата има за цел со пристапни цени и врвен квалитет да се промовира на пазарот на недвижности.
              </p>
            </Col>
            <Col lg={5}>
              <img src={arhitect} className="w-100 aboutArhitect" alt="" />
            </Col>
          </Row>
          <Row className="mt-5 pt-5 pb-5">
            <Col>
              <div className="carousel">
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={kicInt}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={livInt}
                      alt="Second slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Homepage;
