import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Contact.css";

function Contact(props) {
  return (
    <div className="mainContact">
      <div className="firstRow">
        <h1>
          Кон<span>такт</span>
        </h1>
      </div>
      <div className="secondRow mt-2">
        <Container>
          <Row>
            <Col className="contactBorder">
              <Row>
                <Col md={7}>
                  <div className="card mt-2 mb-2">
                    <div className="card-body">
                      <div className="border-bottom">
                        <h5>Адреса</h5>
                        <p>
                          <i className="fas fa-map-marker-alt mr-2"></i> Црвена
                          Вода бр.7, 1000 Скопје
                        </p>
                      </div>
                      <div className="border-bottom pt-2">
                        <h5>E-пошта</h5>
                        <p>
                          <i className="fas fa-envelope mr-2"></i>
                          <a
                            href="mailto:mccconstraksn2025@gmail.com"
                            className="sendMail"
                          >
                            Клик за да испратите
                          </a>
                        </p>
                      </div>
                      <div className="pt-2 border-bottom">
                        <h5>Телефон - Продажба</h5>
                        <p>
                          <i className="fas fa-phone-alt mr-2"></i>
                          +389 78 399 616
                        </p>
                      </div>
                      <div className="pt-2 socialNetwork">
                        <h5>Социјални Мрежи</h5>
                        <div className="pt-2">
                          <a
                            href="https://www.facebook.com/mccconstraksn"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-facebook-square"></i>
                          </a>
                          <a
                            href="https://www.instagram.com/mccconstrution/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={5} className="p-2">
                  <div className="card workTime">
                    <div className="card-body">
                      <h5 className="card-title pb-3">Работно време</h5>
                      <Row>
                        <Col md={6}>
                          <span>Понеделник</span>
                        </Col>
                        <Col md={6}>9:00-17:00</Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <span>Вторник</span>
                        </Col>
                        <Col md={6}>9:00-17:00</Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <span>Среда</span>
                        </Col>
                        <Col md={6}>9:00-17:00</Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <span>Четврток</span>
                        </Col>
                        <Col md={6}>9:00-17:00</Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <span>Петок</span>
                        </Col>
                        <Col md={6}>9:00-17:00</Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <span>Сабота</span>
                        </Col>
                        <Col md={6}>9:00-15:00</Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <span>Недела</span>
                        </Col>
                        <Col md={6}>ЗАТВОРЕНО</Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Contact;
