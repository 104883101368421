import React from "react";
import "./Navigation.css";
import logo from "../../img/logo1.png";
import { Navbar, Nav, NavbarBrand, Container } from "react-bootstrap";

import { Link } from "react-router-dom";

function Navigation() {
  return (
    <div className="navWrap bg-dark">
      <Container>
        <Navbar className="myNav" expand="lg">
          <Link to="/" className="myNavLink">
            <NavbarBrand style={{ width: "70%" }} className="ml-5 text-warning">
              <img alt="" src={logo} className="logoImg d-inline-block align-top" />
            </NavbarBrand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-dark" />
          <Navbar.Collapse id="basic-navbar-nav" className="menuItemz">
            <Nav className="ml-auto">
              <Link to="/" className="ml-2">
                Почетна
              </Link>
              <Link className="ml-2 aboutUsLink" to="/aboutus">
                За Нас
              </Link>
              <Link className="ml-2 aboutUsLink" to="/projects">
                Проекти
              </Link>
              <Link className="ml-2 aboutUsLink" to="/gallery">
                Галерија
              </Link>
              <Link className="ml-2 navContact" to="/contact">
                Контакт
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
}

export default Navigation;
