import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import secondImg from "../../img/slider-2.jpg";
import "./AboutUs.css";

function AboutUs(props) {
  return (
    <div className="mainAboutUs">
      <div className="firstRow">
        <h1>
          За <span>Нас</span>
        </h1>
      </div>
      <div className="secondRow py-5">
        <Container>
          <Row className="firstTab">
            <Col lg={6} className="fTfirstCol">
              <p className="pr-5">"MCC Construction" е градежна компанија формирана во 2016 година која има за цел да се афирмира на пазарот на недвижности со изградба на станбен и деловен простор со прецизен инженеринг и врвен квалитет на вградени материјали.</p>
              <ul>
                <li>
                  <i className="fas fa-check mr-3"></i>
                  <span>Германски фасаден систем</span>
                </li>
                <li>
                  <i className="fas fa-check mr-3"></i>
                  <span>Италијански внатрешни врати со дрвена подконструкција и CPL фолија</span>
                </li>
                <li>
                  <i className="fas fa-check mr-3"></i>
                  <span>Германски шест коморни "Salamander" профили со трослојно четири-сезонско стакло и герамнски оков "Roto"</span>
                </li>
                <li>
                  <i className="fas fa-check mr-3"></i>
                  <span>Првокласен дабов трослоен паркет</span>
                </li>
                <li>
                  <i className="fas fa-check mr-3"></i>
                  <span>Првокласни гранитни плочки</span>
                </li>
                <li>
                  <i className="fas fa-check mr-3"></i>
                  <span>Сертификат за енергетска ефикасност за економично и здраво домување</span>
                </li>
                <li>
                  <i className="fas fa-check mr-3"></i>
                  <span>Сеизмички стабилни градби одобрени од Факултет за Сеизмологија</span>
                </li>
              </ul>
            </Col>
            <Col lg={6}>
              <img src={secondImg} className="img-fluid" alt="" />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default AboutUs;
